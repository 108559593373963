// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/shbg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bg[data-v-b89589e0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:100%;overflow-y:auto;padding:0 .02667rem;box-sizing:border-box;overflow-x:hidden}.title[data-v-b89589e0]{margin:0;text-align:center;font-size:.024rem;padding:.01333rem;border-bottom:.00133rem solid #999}.list ul li[data-v-b89589e0]{padding:.01333rem;background:rgba(0,0,0,.2);margin:.01333rem 0}.msg[data-v-b89589e0]{color:#b6b6b6}.msg .top[data-v-b89589e0]{-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;box-sizing:border-box}.msg .top[data-v-b89589e0],.msg .top .type[data-v-b89589e0]{display:-webkit-box;display:-webkit-flex;display:flex}.msg .top .type[data-v-b89589e0]{color:#489def}.msg .top .type i[data-v-b89589e0]{display:inline-block;margin-right:.01333rem;width:.00667rem;height:100%;background:#489def}.msg .weizhi[data-v-b89589e0]{padding:0 .02667rem}.msg .imgbox[data-v-b89589e0]{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap}.msg .imgbox img[data-v-b89589e0]{width:30%;margin:1.5%}", ""]);
// Exports
module.exports = exports;
