
<template>
<div class="bg">
    <p class="title"><van-icon name="arrow-left" style="float: left;" @click="topath"/><span>历史记录</span><span></span></p>
    <div class="list">
        <ul>
            <li v-for="(item,index) in list" :key="index">
                <div class="msg">
                    <p class="top"><span class="type"><i></i>{{item.Items}}</span><span>{{item.Survey_Time.split('.')[0].replace('T',' ')}}</span></p>
                    <p class="weizhi">位置:&nbsp;&nbsp;{{ item.Place }}</p>
                    <p class="weizhi">描述:&nbsp;&nbsp;{{ item.Survey_Result }}</p>
                    <div class="imgbox">
                        <img v-for="i in item.Survey_File.split('*')" :key="i.index" :src="i" alt="">
                    </div>
                </div>

            </li>
        </ul>
    </div>
</div>
</template>

<script>
import router from '../../router'
import request from "@/utils/request.js";

export default {
  name: '',
  components: {},
  data () {
    return {
        list:[
            {
                type:'玉米',
                time:'2023-04-01 15:00',
                weizhi:'邯郸市临漳县',
                remark:'秋收季 如何减损增产保丰收?',
                img:['/image/aimg_3.png','/image/aimg_3.png'],
                file:''
            },
            {
                type:'玉米',
                time:'2023-04-01 15:00',
                weizhi:'邯郸市临漳县',
                remark:'秋收季 如何减损增产保丰收?',
                img:['/image/aimg_3.png','/image/aimg_3.png'],
                file:''
            }
        ]
    }
  },
  created () { },
  mounted () { },
  methods: { 
    topath(){
      this.$router.go(-1)
    },
   async getlist(){
let res=await request({
    url:'/api/Survey/GetList',
    method:'get',
    params:{
        pageNum:'1',
        pageSize:'-1'
    }
})
this.list=res.data.data
    }
  },
  computed: {}
}
</script>
<style scoped lang='scss'>
.bg{
    background-image: url('~@/assets/images/shbg.jpg');
    width: 100%;
    height:100%;
    overflow-y: auto;
    padding: 0 20px;
    box-sizing: border-box;
    overflow-x:hidden ;
}
.title{
    margin:0;
    text-align: center;
    font-size:18px;
    padding:10px;
    border-bottom:1px solid #999
}
.list{
    ul{
        li{
            padding:10px;
            background: rgba(0,0,0,0.2);
            margin:10px 0;
        }
    }
}
.msg{
    color:#b6b6b6;
.top{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .type{
        display: flex;
        color: rgb(72,157,239);
        i{
            display: inline-block;
            margin-right:10px ;
            width: 5px;
            height:100%;
            background: rgb(72,157,239);
        }
    }
}
.weizhi{
    padding:0 20px
}
.imgbox{
    display: flex;
    flex-wrap:wrap ;
    img{
        width: 30%;
        margin: 1.5%;
    }
}
}
</style>
